import React from "react"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const Advertisement = ({ content: { logos, title } }) => {
  return (
    <Section className="advertisement inner-padding">
      <Container>
        <h2 className="title text-center">{title}</h2>
        <div className="row">
          <div className="col-sm-12 col-xs-12">
            <div className="client-wrapper">
              <div className="cl-item-wrapper">
                {logos.map(
                  (logo, idx) =>
                    idx < 3 && (
                      <div className="cl-item" key={logo._rev}>
                        <Image image={logo.image.asset} isData />
                      </div>
                    )
                )}
              </div>
              <div className="cl-item-wrapper">
                {logos.map(
                  (logo, idx) =>
                    idx >= 3 && (
                      <div className="cl-item" key={logo._rev}>
                        <Image image={logo.image.asset} isData />
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default Advertisement
