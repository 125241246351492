import React, { useState } from "react"

const FaqList = ({ faqs }) => {
  const [selectedFaq, setSelectedFaq] = useState(0)

  return (
    <>
      <div className="accordion-wrapper">
        {faqs?.length &&
          faqs.map((faq, idx) => {
            const isActive = selectedFaq === idx ? "active" : ""

            return (
              <div
                className={`accordion-item ${isActive}`}
                key={idx}
                onClick={() => (selectedFaq === idx ? setSelectedFaq(-1) : setSelectedFaq(idx))}
              >
                <div className="accordion-link">
                  {faq.question}
                  <span className="accordion-icon"> </span>
                </div>
                <div className="accordion-answer">
                  <p className="accordion-answer-text">{faq.answer[0].children[0].text}</p>
                </div>
              </div>
            )
          })}
      </div>

      <div className="view-more">
        {" "}
        <a href="/return-faq-shipping/" className="link-hvr red" target="_blank">
          View more FAQs
        </a>{" "}
      </div>
    </>
  )
}

export default FaqList
