import React, { useState } from "react"
import Image from "components/atoms/Image"

const NUTRITIONS = ["sugar", "netCarbs", "fiber", "calories", "carbs", "protein"]

const StackUpTable = ({ notes, isTableCenter, stacks, data, headers, compare }) => {
  return (
    <div className="stackup-table-wrapper">
      <table className={isTableCenter ? "stackup-table stackup-table-center" : "stackup-table"}>
        <tbody>
          <tr className="column">
            <th></th>
            {headers?.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
          {compare ? (
            <>
              <tr>
                <th className="kmk-image">
                  <Image image={data.ourProducts?.image?.asset} isData />
                </th>
                {NUTRITIONS.map((content, contentId) => (
                  <td key={contentId} className={"bg-green text-white"}>
                    {`${data.ourProducts.stackContent[content]}${content.includes("calories") ? "" : "g"}`}
                  </td>
                ))}
              </tr>
              <tr>
                <th className="kmk-image">
                  <Image image={data.otherProducts?.image?.asset} isData />
                </th>
                {NUTRITIONS.map((content, contentId) => (
                  <td key={contentId}>{`${data.otherProducts.stackContent[content]}${
                    content.includes("calories") ? "" : "g"
                  }`}</td>
                ))}
              </tr>
            </>
          ) : (
            stacks?.map((stack, stackId) => {
              return (
                <tr key={stackId}>
                  <th className={stackId === 0 ? "kmk-image" : ""}>
                    <Image image={stack?.image?.asset} isData />
                  </th>
                  {NUTRITIONS.map((content, contentId) => (
                    <td key={contentId} className={stackId === 0 ? "bg-green text-white" : ""}>
                      {`${stack.stackContent[content]}${content.includes("calories") ? "" : "g"}`}
                    </td>
                  ))}
                </tr>
              )
            })
          )}
        </tbody>
      </table>

      <div className={isTableCenter ? "stack-details stack-details-center" : "stack-details"}>
        <p>{notes}</p>
      </div>
    </div>
  )
}

export default StackUpTable
