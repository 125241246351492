import React, { useRef, useState } from "react"

import ReactPlayer from "react-player"
import PlayIcon from "../../../assets/images/play-video.svg"

const TapToPlaySoundVideo = ({ url, ...props }) => {
  const [muted, setMuted] = useState(true)
  const videoRef = useRef("videoRef")
  const playVideo = e => {
    e.preventDefault()
    setMuted(false)
    videoRef.current.seekTo(0)
  }

  return (
    <div className="tap-video-container">
      <ReactPlayer ref={videoRef} url={url} playing playsinline muted={muted} volume={1} controls {...props} />
      {muted && (
        <div className="play-the-sound" onClick={playVideo}>
          <img src={PlayIcon} alt="" />
          <p>Tap to play sound</p>
        </div>
      )}
    </div>
  )
}

export default TapToPlaySoundVideo
