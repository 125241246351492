import React, { useState, useEffect } from "react"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import FaqList from "components/molecules/FaqList"

const FaqSection = ({ content }) => {
  const { faqs, title } = content
  const [isFaqShipping, setIsFaqShipping] = useState(false)

  useEffect(() => {
    const isShippingFaq = window.location.pathname.includes("return-faq-shipping")
    setIsFaqShipping(isShippingFaq)
  })

  return (
    <Section className="faq-section section-wrapper">
      <Container>
        <div className="faq-wrapper max-650">
          {isFaqShipping ? <h1 className="title">{title}</h1> : <h2 className="title">{title}</h2>}
          <FaqList faqs={faqs} />
          <div className="contact-faqs">
            <div className="contact-inline">
              <div className="contact">
                <i className="icon-phone"></i> Text KMKHELP to 49882
              </div>
              <div className="contact">
                <i className="icon-help"></i> Help Center
              </div>
            </div>
            <div className="contact">
              <i className="icon-chat"></i> Chat with our us. We’re available Monday- Friday 9am to 6pm (EST)
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default FaqSection
